<ng-container *ngIf="errorMsg; else elseTemplate">
  <div class="onlyerrorMsg">
    <img src="assets/images/linkExpired.png" alt="">
    <h4 class="text-center"><strong>Link Expired</strong></h4>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="col-md-12 top-part">
    <ng-container *ngIf="selectedAuthConfiguration">
      <div class="login-form">
        <div class="row">
          <div class="col-md-12 text-center">
            <img [src]="getClientLogo()" alt="">
          </div>
          <div class="col-md-12 headerAuth">
            <h1>Welcome</h1>
            <p>Please fill the relevant information</p>
          </div>
          <div class="col-md-12" *ngIf="message">
            <span>{{ message }}</span>
          </div>
        </div>

        <form class="login-container wizardAuthLogin">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group loginGroup">
                <label>Email</label>
                <input type="email" class="form-control loginEmail" placeholder="Username" name="username"
                  [(ngModel)]="validateApiKeyRequest.username">

              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="selectedAuthConfiguration.authType == 'BASIC_AUTH'" class="col-md-12">
              <div class="form-group loginGroup loginPass">
                <label>Password</label>
                <input type="password" class="form-control loginEmail " placeholder="password" name="password"
                  [(ngModel)]="validateApiKeyRequest.password">
              </div>
            </div>
          </div>
          <div class="row" *ngIf="selectedAuthConfiguration.authType == 'OTP_BASED' && otpSent">
            <!--  *ngIf="selectedAuthConfiguration.authType == 'OTP_BASED' && otpSent" -->
            <div class="col-md-12">
              <div class="form-group">
                <ng-container *ngIf="showResendOtp == false; else elseShowResendOtpTemplate">
                  <label class="otpLabel">OTP <small class="resendOtp">Resend in {{timerLimit}}</small></label>
                  
                </ng-container>
                <ng-template #elseShowResendOtpTemplate>
                  <label class="otpLabel">OTP <small class="resendOtp" *ngIf="otpSent" (click)="sendOTP()">Resend OTP</small></label>
                </ng-template>
                
                <input type="hidden" class="form-control" name="otp" id="partitioned" maxlength="5"
                  [(ngModel)]="validateApiKeyRequest.otp">
                <div class="flexContainer">
                  <input type="password" name="otp1" id="otp1" class="form-control" maxlength="1" [(ngModel)]="otp1" (blur)="updateApiKeyRequest()" (input)="goToNextOtp('otp2',$event)" tabindex="1">
                  <input type="password" name="otp2" id="otp2" class="form-control" maxlength="1" [(ngModel)]="otp2" (blur)="updateApiKeyRequest()" (input)="goToNextOtp('otp3',$event)" tabindex="2">
                  <input type="password" name="otp3" id="otp3" class="form-control" maxlength="1" [(ngModel)]="otp3" (blur)="updateApiKeyRequest()" (input)="goToNextOtp('otp4',$event)" tabindex="3">
                  <input type="password" name="otp4" id="otp4" class="form-control" maxlength="1" [(ngModel)]="otp4" (blur)="updateApiKeyRequest()" tabindex="4">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="selectedAuthConfiguration.authType == 'OTP_BASED' && !otpSent" class="col-md-12">
              <div class="form-group">
                <input type="submit" class="btn btn-primary btn-block" [value]="otpSent ? 'RESEND OTP' : 'Send OTP'"
                  (click)="sendOTP()" />
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="selectedAuthConfiguration" class="col-md-12">
              <div *ngIf="selectedAuthConfiguration.authType == 'OTP_BASED' && otpSent" class="form-group">
                <input type="submit" class="btn btn-primary btn-block" value="LOGIN" (click)="authenticate()" />
              </div>
              <div *ngIf="selectedAuthConfiguration.authType == 'BASIC_AUTH'" class="form-group">
                <button class="btn btn-sm btn-primary btn-block" (click)="authenticate()">Login</button>
                <!-- <input type="submit" class="btn btn-primary btn-block" value="LOGIN" (click)="authenticate()" /> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngFor="let auth of filteredAuthConfigurationList" class="col-md-12 loginWithPass">
              <div class="col-md-12 demarkation">
                <div class="middleStrike"></div>
                <span>OR</span>
                <div class="middleStrike"></div>
              </div>
              <div *ngIf="auth.authType == 'BASIC_AUTH' && auth.userType == 'EXTERNAL_USER'"
                class="form-group text-center mt-4">
                <span (click)="onAuthChange(auth)" class="link">Login with Password</span>
                <!-- <input type="submit" class="btn btn-primary btn-block" value="Login with external user"
                  (click)="onAuthChange(auth)" /> -->
              </div>
              <div *ngIf="auth.authType == 'OTP_BASED' && auth.userType == 'EXTERNAL_USER'"
                class="form-group text-center mt-4">
                <span (click)="onAuthChange(auth)" class="link">Login with OTP</span>
                <!-- <input type="submit" class="btn btn-primary btn-block" value="Login with external user via otp"
                  (click)="onAuthChange(auth)" /> -->
              </div>
              <div class="form-group text-center" *ngIf="auth.userType == 'SYSTEM_USER'">
                <strong>System User</strong>
              </div>
              <div *ngIf="auth.authType == 'BASIC_AUTH' && auth.userType == 'SYSTEM_USER'"
                class="form-group text-center mt-4">
                <span (click)="onAuthChange(auth)" class="link">Login with Password</span>
                <!-- <input type="submit" class="btn btn-primary btn-block" value="Login with system user"
                  (click)="onAuthChange(auth)" /> -->
              </div>
              <div *ngIf="auth.authType == 'OTP_BASED' && auth.userType == 'SYSTEM_USER'"
                class="form-group text-center mt-4">
                <span (click)="onAuthChange(auth)" class="link">Login with OTP</span>
                <!-- <input type="submit" class="btn btn-primary btn-block" value="Login with system user via otp"
                  (click)="onAuthChange(auth)" /> -->
              </div>
            </div>


          </div>


        </form>
      </div>
    </ng-container>

  </div>
</ng-template>