import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AuthConfigurationModel } from "src/app/models/authConfiguration.model";
import { User } from "src/app/models/user.model";
import { ValidateApiKeyRequest } from "src/app/models/validateApiKeyRequest.model";
import { ValidationApiKeyResponse } from "src/app/models/validationApiKeyResponse.model";
import { AccessControlService } from "src/app/services/accesscontrol.service";
import { EnvironmentData } from "src/app/services/environments.service";
import { AuthService, DataSharingService, SecuredStorageService } from "src/app/services/shared.service";

@Component({
    selector: 'auto-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
    errorMsg: string | "";
    message: string | "";
    validateApiKeyRequest: ValidateApiKeyRequest;
    otpSent: boolean = false;
    otp1:number;
    otp2:number;
    otp3:number;
    otp4:number;
    timerLimit:number = 30;
    showResendOtp:boolean = false;

    authConfigurationList: AuthConfigurationModel[];
    filteredAuthConfigurationList: AuthConfigurationModel[];
    selectedAuthConfiguration: AuthConfigurationModel;


    @Input()
    apiKey: string;

    @Output() onAuthSuccess: EventEmitter<any> = new EventEmitter();
    
    constructor(
        private authService: AuthService,
        private securedStorageService: SecuredStorageService,
        private environmentData: EnvironmentData,
        private accessControlService: AccessControlService,
        private dataSharingService: DataSharingService
    ) {
        this.validateApiKeyRequest = new ValidateApiKeyRequest();
        this.filteredAuthConfigurationList = [];     
    }

    ngOnInit(): void {
        this.validateApiKeyRequest.apiKey = this.apiKey
        this.validateApiKey();
    }

    ngOnDestroy(): void {

    }
    apiKeyExpired:boolean = false;
    validateApiKey() {
        if (this.apiKey) {
            this.authService.validateApiKey(this.apiKey)
            .subscribe(
                response => {
                    // console.log("validateApiKeyResponse", response);
                    if (response.jwt) {
                        this.sendUserWithJWT(response);
                    }
                    else {
                        this.authConfigurationList = response.authConfigurationList;
                        for (let authConfig of this.authConfigurationList) {
                            if (authConfig.defaultAuth) {
                                this.selectedAuthConfiguration = authConfig;
                                break;
                            }
                        }
                        if (!this.selectedAuthConfiguration) {
                            this.selectedAuthConfiguration = this.authConfigurationList[0];
                        }
                        this.filteredAuthConfigurationList = this.getAllowedAuthConfigurations();
                    }
                },
                error=>{
                    this.apiKeyExpired = true;
                    this.errorMsg = error.error.message
                }
            )
        }
        else {
            this.authService.getToken()
                .subscribe (
                    response => {
                        if (response) {
                            this.environmentData.getEnvData().flowAPI = "/flow-api/";
                            const user: User = new User()
                            user.jwt = response.result.authToken;
                            this.onAuthSuccess.emit(user);
                        }
                    },
                    error => {

                    }
                )
        }

        
    }

    sendUserWithJWT(validationResponse: ValidationApiKeyResponse) {
        const user = new User();
        user.jwt = validationResponse.jwt;
        user._basePath = "/flow-api";
        this.authService.setBearerToken(validationResponse.jwt);
        this.environmentData.getEnvData().flowAPI = "/flow-api/";
        this.onAuthSuccess.emit(user);
    }

    basicAuthAllowed() {
        return true;
    }

    otpBasedAllowed() {
        return false;
    }

    sendOTP() {
        this.validateApiKeyRequest.authConfiguration = this.selectedAuthConfiguration;
        this.authService.sendOTP(this.validateApiKeyRequest)
            .subscribe(
                response => {
                    if (response.otpSent) {
                        this.otpSent = true;
                        //this.toastrService.success("OTP sent successfully");
                        this.timerLimit = 30;
                        this.showResendOtp = false;
                        const myInterval = setInterval(() => {
                            this.timerLimit--;

                            if(this.timerLimit == 0){
                                clearInterval(myInterval);
                                this.showResendOtp = true
                            }
                          }, 1000);
                    }
                },
                error => {

                }
            )
    }

    authenticate() {
        if (this.selectedAuthConfiguration.authType == 'OTP_BASED') {
            this.validateApiKeyRequest.password = null;
        }
        if (this.selectedAuthConfiguration.authType == 'BASIC_AUTH') {
            this.validateApiKeyRequest.otp = null;
        }
        this.validateApiKeyRequest.authConfiguration = this.selectedAuthConfiguration;
        this.message = null;
        this.authService.authenticate(this.validateApiKeyRequest)
            .subscribe(
                user => {
                    if (user.authorities && user.authorities.includes("ROLE_EXTERNAL_USER")) {
                        this.environmentData.getEnvData().flowAPI = "/flow/externaluser/";
                        user._basePath = "/flow/externaluser";
                    }
                    else {
                        this.environmentData.getEnvData().flowAPI = "/flow/console/";
                        user._basePath = "/flow/console";
                    }
                    this.onAuthSuccess.emit(user);
                    
                },
                error => {
                    if (error.status == 401) {
                        this.message = error.error.message
                        // this.toastrService.error(error.error.message);
                    }
                }
            )
    }

    getAllowedAuthConfigurations() {
        const filteredAuthConfigList: AuthConfigurationModel[] = [];
        if (this.authConfigurationList) {
            for (let auth of this.authConfigurationList) {
                if (auth.authType == this.selectedAuthConfiguration.authType && auth.userType == this.selectedAuthConfiguration.userType) {
                    continue;
                }
                filteredAuthConfigList.push(auth);
            }
        }
        return filteredAuthConfigList;
    }

    onAuthChange(authConfig: AuthConfigurationModel) {
        this.selectedAuthConfiguration = authConfig;
        this.filteredAuthConfigurationList = this.getAllowedAuthConfigurations();
    }

    getSelectedAuthconfig() {
        return JSON.stringify(this.selectedAuthConfiguration);
    }

    getClientLogo(){
        return this.dataSharingService['clientLogo'];
    }
    updateApiKeyRequest(){
        if(this.otp1 && this.otp2 && this.otp3 && this.otp4){
            this.validateApiKeyRequest.otp = ("" + this.otp1 + this.otp2 + this.otp3 + this.otp4); 
        }
    }
    goToNextOtp(divId,event){

        let element;
        if (event.code !== 'Backspace')
             element = event.srcElement.nextElementSibling;
     
         if (event.code === 'Backspace')
             element = event.srcElement.previousElementSibling;
     
         if(element == null)
             return;
         else
             element.focus();
     }
}